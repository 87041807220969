// ==========================================================================
// header
// ==========================================================================
#header {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  @if $type == pc{
    padding: 0 50px;
    height: 266px;
  }
  @if $type == sp{
    height: 70px;
    padding: 0 20px;
  }
  #logo {
    // border: 1px solid $gray10;
    display: none;
    align-items: center;
    @if $type == pc{
      padding-right: 20px;
    }
    @if $type == sp{
      padding-right: 10px;
    }
    .logo01 {
      @if $type == pc{
        margin-left: 20px;
        width: 300px;
      }      
      @if $type == sp{
        margin-left: 10px;
        width:108px;
      }
    }
    .logo02 {
      @if $type == pc{
        padding: 27px;
        width: 150px;
      }
      
      @if $type == sp{
        padding: 0 10px;
        width: 54px;
      }
    }
    img{
      width:100%;
    }
  }
}

// ==========================================================================
// gnav
// ==========================================================================
#sns_list{
  @include flex(flex-start,flex-start,row,nowrap);
  align-items: center;
  margin: 20px 0 50px;
  @if $type == sp {
    margin-bottom: 30px;
  }
  li{
    + li {
      margin-left: 40px;
      a {
        font-size: 30px;
        @if $type == sp{
          font-size: 25px;
        }
      }
    }
  }
  a{
    font-size: 34px;
    @if $type == pc{
      color:$gray01;
      &:hover{
        color:$main_color;
      }
    }
    @if $type == sp{
      font-size: 28px;
      color:$gray05;
    }
  }
}

#gnav{
  @if $type == pc{
    font-size: 20px;
  }
  @if $type == sp{
    font-size:16px;
  }
  li {
    text-align:left;
  }
  a{
    text-decoration:none;
    &:hover {
      opacity: 0.7;
    }
    @if $type == pc{
      padding: 15px 0;
      position: relative;
      display: inline-block;
      text-decoration: none;
    }
    @if $type == sp{
      display: block;
      color:$gray06;
      font-weight:500;
      padding: 10px 0;
    }
  }
}

#nav_btn {
  cursor: pointer;
  display: block;
  width: 40px;
  height: 26px;
  position: fixed;
  @if $type == pc{
    top: 60px;
    right: 50px;
  }
  @if $type == sp {
    height: 22px;
    top: 30px;
    right: 20px;
  }
  span {
    width: 100%;
    height: 2px;
    left: 0;
    background-color: $white;
    position: absolute;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 0;bottom: 0;
      margin:auto;
    }
    &:nth-child(3) { 
      bottom: 0;
    }      
  }
  &.active {
    span {
      background-color: $main_color;
    }
    z-index: 99;
    span:nth-child(1) {
      -webkit-transform: translateY(12px) rotate(45deg);
      transform: translateY(12px) rotate(45deg);
    }
    span:nth-child(2) { opacity: 0; }
    span:nth-child(3) {
      -webkit-transform: translateY(-11px) rotate(-45deg);
      transform: translateY(-11px) rotate(-45deg);
      @if $type == sp {
        bottom: -2px;
      }
    }
  }
}

#menu_box {  
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba($black, 0.5);
  position: fixed;
  display: flex;
  align-items: center;  
  @include trans($duration: .3);
  opacity: 0;
  z-index: -10;
  visibility: hidden;
  @if $type == pc {
    background: rgba($black, 0.5);
    justify-content: end;
  }
  @if $type == sp {
    background: rgba($white, 1);
    overflow-y: auto;
    justify-content: center;
  }
  .menu_inner {    
    @if $type == pc {    
      padding: 80px 125px 50px; 
      overflow-y: auto;
      background: $white;
      height: 100%;
      width: 520px;
    }    
  }
}

#menu_box.opened {
  opacity: 1;
  z-index: 98;
  visibility: visible;
}
.bnr {
  a {
    display: block;
    &:hover {
      opacity: 0.7;
    }
  }
  img {
    width: 100%;
    @if $type == sp {
      width: 204px;
    }
  }
}

#pagetop {
  #header {
    #logo {
      display: flex;
    }
  }
  #nav_btn {
    span {
      background-color: $main_color;
    }
  }
}

.fixed.inpage {
  #nav_btn {
    span {
      background-color: $main_color;
    }
  }
}
