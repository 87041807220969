// ==========================================================================
// config
// ==========================================================================
// font-family
$font_sans: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
$font_serif: 'Noto Serif JP',"游明朝", "YuMincho", "Hiragino Mincho ProN", "HG明朝E", serif;
$font_crimson: 'Crimson Text', serif;
$font_arvo: 'Arvo', serif;
$font_awes: "Font Awesome 5 Free";
$font_awes_brand: "Font Awesome 5 Brands";

// color
$main_color: #595757;
$sub_color:#88c6d4;
$key_color:#ffffff;
$text_color:#585858;
$aqua:#3eacc3;
$white:#fff;
$black:#000;
$black02:#2B2B2B;
$bg_gray:#f7f7f7;
$red:#b94343;
$red02:#a13535;

$sat:#5975a8;
$sun:#bd4e4e;

$gray01:#c6c6c6;
$gray02:#9e9e9e;
$gray03:#3e3e3e;
$gray04:#ababab;
$gray05:#b1b0b0;
$gray06:#4d4b4b;
$gray07:#2d2d2d;
$gray08:#dfe6e9;
$gray09:#b8b8b8;
$gray10:#F0F0F0;
$gray11:#898989;

//
$main-width: 1240px;

// ==========================================================================
// mixin
// ==========================================================================
@mixin clearfix {
 &::after {
  content: "";
  display: block;
  clear: both;
 }
}

@mixin trans($property: all, $duration: .3, $ease: ease){
        -webkit-transition: $property #{$duration}s $ease;
        -moz-transition: $property #{$duration}s $ease;
        transition: $property #{$duration}s $ease;
}

@mixin flex($align:flex-start, $justy:flex-start, $dir:row, $wrap:nowrap) {
    display        : flex;
    align-items    : $align;
    justify-content: $justy;
    flex-direction : $dir;
    flex-wrap      : $wrap;
}

@mixin opacity($opacity:.7) {
    &:hover {
       opacity: $opacity;
    }
}

@mixin lh($fontSize, $height) {
  line-height: $height / $fontSize;
}

@mixin fz($size: 14) {
    font-size: $size + px;
    font-size: ($size / 10) + rem;
}

@mixin text_hide{
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

@mixin inner {
  max-width: $main-width;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
}

// ==========================================================================
// keyframes
// ==========================================================================
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes scroll {
  0% {
    height: 0;
    bottom:auto;
    top:72px;
  }

  49% {
    top: 72px;
    bottom:auto;
    height: 60px;
  }

  50% {
    bottom: 0;
    top: auto;
    height: 60px;
  }

  100% {
    bottom: 0;
    top: auto;
    height: 0;
  }
}

@keyframes scroll {
  0% {
    height: 0;
    bottom:auto;
    top:72px;
  }

  49% {
    top: 72px;
    bottom:auto;
    height: 60px;
  }

  50% {
    bottom: 0;
    top: auto;
    height: 60px;
  }

  100% {
    bottom: 0;
    top: auto;
    height: 0;
  }
}
@-webkit-keyframes scroll_sp {
  0% {
    height: 0;
    bottom:auto;
    top:12.8vw;
  }
  49% {
    top:12.8vw;
    bottom:auto;
    height: 12vw;
  }
  50% {
    bottom: 0;
    top: auto;
    height: 12vw;
  }
  100% {
    bottom: 0;
    top: auto;
    height: 0;
  }
}

@keyframes scroll_sp {
  0% {
    height: 0;
    bottom:auto;
    top:12.8vw;
  }
  49% {
    top:12.8vw;
    bottom:auto;
    height: 12vw;
  }
  50% {
    bottom: 0;
    top: auto;
    height: 12vw;
  }
  100% {
    bottom: 0;
    top: auto;
    height: 0;
  }
}

@-webkit-keyframes top_main {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
@keyframes top_main {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
@-webkit-keyframes top_concept {
    0% {
        opacity:0;
    }
    25% {
        opacity:.1;
    }
    75% {
        opacity:.9;
    }
    100% {
        opacity:1;
    }
}
@keyframes top_concept {
    0% {
        opacity:0;
    }
    25% {
        opacity:.1;
    }
    75% {
        opacity:.9;
    }
    100% {
        opacity:1;
    }
}
