// ==========================================================================
// top
// ==========================================================================
// common
// ==========================================================================
.top_ttl{
  font-weight:400;
  @if $type == pc{
    font-size:30px;
  }
  @if $type == sp{
    font-size:18px;
  }
}

// top_main
// ==========================================================================
#top_main{
    // background:url(../../img/main_bg.png) no-repeat center;
    // background-size:cover;
  position: relative;
  @if $type == pc{
    // height:60vw;
    margin-top: 266px;
    overflow: hidden;
  }
  @if $type == sp{
    margin-top: 70px;
    // height:round(100vw / 3 * 4);
  }

  .main_slider {
    position: relative;
    margin: 0 auto;
    width: calc(100% - 100px);
    @if $type == pc {
      height: 35vw;
      width: calc(100% - 100px);
    }
    @if $type == sp {
      height: 145vw;
      width: calc(100% - 40px);
    }
    .slide_img {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;
      height: 100%;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: all 7s ease-out;
      img {
        object-position: center;
        object-fit: cover;
        height: 100%!important;
        width: 100%!important;
      }
      &.current {
        opacity: 1;
        visibility: visible;
        animation: slideMove 1s ease-out forwards;
        z-index: 5;
        img {
          animation: slideImg 12s ease-out forwards;
          //transform: scale(1.1);
        }
      }
    }
  }
  @keyframes slideMove {
    0% {
      opacity: 0.5;
    }
  
    100% {
      opacity: 1;
    }
  }
  @keyframes slideImg {
    0% {
      //opacity: 0.5;
      filter: brightness(1);
      transform: scale(1);
    }
  
    100% {
      //opacity: 1;
      filter: brightness(1.25);
      transform: scale(1.1);
    }
  }

  .story{
    overflow:hidden;
    position: absolute ;
    opacity:0;
    @if $type == pc{width:40vw;height:40vw;top:20vw;left:40vw;}
    @if $type == sp{width:round(100vw / 3 * 2);height:round(100vw / 3 * 2);top:0;left:round(100vw / 3);}
  }
  &.active li,&.active .story{
    -webkit-animation: top_main 2.5s ease-in-out .5s forwards;
    animation: top_main 2.5s ease-in-out .5s forwards;
  }
  a{
    display:block;
    position: relative;
    width:100%;
    height:100%;
    text-decoration:none;
    @if $type == pc{
    }
    @if $type == sp{
    }
    .ov{
      opacity:0;
      z-index:1;
      @if $type == pc{
        position: absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        background: rgba($sub_color,.9);
        color:$white;
        font-family:$font_sans;
        font-size:12px;
        padding:35px 40px;
        @include trans($duration: .5);
        overflow: hidden;
        width: -webkit-calc(100% + 20px);
        width: calc(100% + 20px);
        height: 100%;
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll;
        .date{
          padding:20px 0 0;
        }
      }
      @if $type == sp{
      }
    }
    &:hover .ov{
      @if $type == pc{
        opacity:1;
        @include trans($duration: .3);
      }
    }
    &::after{
      font-family:$font_awes_brand;
      background:$white;
      color:$gray05;
      content:"\f16d";
      bottom:0;
      right:0;
      position: absolute;
      z-index:0;
      @if $type == pc{
        text-align:center;
        line-height:40px;
        width:40px;
        height:40px;
        font-size:17px;
      }
      @if $type == sp{
        font-size:14px;
        text-align:center;
        line-height:6.6vw;
        width:6.6vw;
        height:6.6vw;
      }
    }
    .date{
      font-family:$font_arvo;
      @if $type == pc{
      }
      @if $type == sp{
      }
    }
  }

  .scroll{
    bottom:0;
    opacity:1;
    color:$white;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    @include trans();
    @if $type == pc{
      position: fixed;
      font-size:14px;
      left:30px;
      padding:0 0 70px;
    }
    @if $type == sp{
      position: absolute;
      font-size:10px;
      left:4vw;
      padding:0 0 13vw;
    }
    &::after{
      content:"";
      background:$white;
      position: absolute;
      bottom:0;
      left:0;
      right:0;
      margin:auto;
      z-index:0;
      width:1px;
      @if $type == pc{
        -webkit-animation: scroll 3s linear infinite;
        animation: scroll 3s linear infinite;
        height:60px;
      }
      @if $type == sp{
        -webkit-animation: scroll_sp 3s linear infinite;
        animation: scroll_sp 3s linear infinite;
        height:12vw;
      }
    }
  }
}

.fixed #top_main .scroll{
  opacity:0;
}

// top_news
// ==========================================================================
#top_news{
  @if $type == pc {
    padding:100px 0 40px;
  }
  @if $type == sp {
    padding:50px 0 25px;
    .inner {
      padding: 0;
    }
  }
  .top_ttl{
    text-align:center;
    @if $type == pc{
    }
    @if $type == sp{
    }
  }
  .slide{
    position: relative;
    @if $type == pc{
      padding:60px 0 0;
    }
    @if $type == sp{
      padding:30px 0 0;
    }
    .slick-list,
    .slick-track {
      @if $type == pc{
        display: flex;
        justify-content: center;       
      }
    }
    &.center {
      .slick-track {
        @if $type == pc{
          transform: translate3d(0, 0px, 0px)!important;
        }
      }
    }
    .slick-slide {
      box-sizing: content-box;
      @if $type == pc{
        padding:0 23px;
        width: 224px;
      }
      @if $type == sp{
        padding:0 1.5vw;
        width: 258px;
      }
    }
    a{
      text-decoration:none;
      color:$gray07;
      display:block;
      @if $type == pc{
      }
      @if $type == sp{
      }
      &:hover{
        @if $type == pc{
          color:$sub_color;
          @include opacity(.7);
        }
      }
    }
  }
  h3{
    @if $type == pc{
      padding:12px 0 0;
    }
    @if $type == sp{
      padding:10px 0 0;
      font-size:12px;
    }
  }
  .date{
    font-family:$font_arvo;
    color:$gray01;
    @if $type == pc{
      font-size:12px;
    }
    @if $type == sp{
      font-size:10px;
    }
  }
  .pic{
    border: 1px solid $gray10;
    margin: 0 auto;
    text-align:center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    @if $type == pc{
      height:280px;
      width: 100%;
    }
    @if $type == sp{
      height:322px;
      width: 100%;
    }
    img{
      width:100%;
      height:100%;
      object-position: center;
      object-fit: cover;
      @if $type == pc{
      }
      @if $type == sp{
      }
    }
  }
  /* slick */
  .slick-dots{
    @include flex(center,center,row,nowrap);
    position:relative;
    @if $type == pc{
      padding:60px 0 0;
    }
    @if $type == sp{
      padding:25px 0 0;
    }
    li{
      width:auto;
      height:auto;
      margin:0;
      @if $type == pc{
        padding:0 6px;
      }
      @if $type == sp{
        padding:0 7px;
      }
    }
    button{
      background:$gray08;
      border:none;
      @include text_hide;
      padding:0;
      height:5px;
      @if $type == pc{
        width:110px;
      }
      @if $type == sp{
        width:40px;
      }
      &::before{
        display:none;
      }
    }
    .slick-active button{
      background:$sub_color;
    }
  }
  .slick-arrow{
    position: absolute;
    background:$sub_color;
    border:none;
    @include text_hide;
    padding:0;
    z-index:5;
    height: 40px;
    width: 40px;
    @if $type == pc{
      top: 200px;
    }
    @if $type == sp{
      height: 24px;
      width: 30px;
      top: 185px;
    }
    &::before,&::after{
      content:"";
      background:$white;
      height:1px;
      position:absolute;
      @if $type == pc{
      }
      @if $type == sp{
      }
    }
    &::before{
      left:0;
      top:0;
      right:0;
      bottom:0;
      margin:auto;
      width:22px;
      @if $type == sp{
        width: 15px;
      }
    }
    &::after{
      top: 16px;
      width: 10px;
      @if $type == sp{
        top: 9px;
        width: 6px;
      }
    }
  }
  .slick-prev{
    @if $type == pc{
    }
    @if $type == sp{
     left:3.5vw;
    }
    &::after{
      left: 8px;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      @if $type == pc{
      }
      @if $type == sp{
      }
    }
  }
  .slick-next{
    @if $type == pc{
    }
    @if $type == sp{
     right:3.5vw;
    }
    &::after{
      right: 8px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      @if $type == pc{
      }
      @if $type == sp{
      }
    }
  }
}

// top_concept
// ==========================================================================
#top_concept{
  h2 {
    margin-bottom: 40px;
    text-align: center
  }
  @if $type == pc{
    padding: 100px 0 0;
  }
  @if $type == sp{
    padding:50px 0 0;
    h2 {
      margin-bottom: 30px;
    }
  }
  .reed_txt{
    @if $type == pc{
      padding:10px 0 0;
      position: relative;
      z-index: 5;
    }
    @if $type == sp{
      padding:12px 0 0;
      font-size:12px;
    }
    p{
      @if $type == pc{
        padding: 30px 0 0;
      }
      @if $type == sp{
        padding:25px 0 0;
      }
    }
  }
  .pic_list{
    position: relative;
    @if $type == pc{
      height:765px;
    }
    @if $type == sp{
      height:138vw;
      margin:30px -20px 0;
    }
    li{
      position: absolute;
      overflow:hidden;
      background:$bg_gray;
      @if $type == pc{
      }
      @if $type == sp{
      }
      &:nth-child(1){
        @if $type == pc{top:-115px;right:315px;}
        @if $type == sp{width:36vw;top:0;right:4vw;}
      }
      &:nth-child(2){
        @if $type == pc{top:45px;right:0;}
        @if $type == sp{width:31vw;top:100vw;right:39vw;}
      }
      &:nth-child(3){
        @if $type == pc{top:105px;right:315px;}
        @if $type == sp{width:49vw;top:25vw;right:4vw;}
      }
      &:nth-child(4){
        @if $type == pc{top:208px;right:808px;}
        @if $type == sp{width:31vw;top:36vw;right:57vw;}
      }
      &:nth-child(5){
        @if $type == pc{top:235px;right:0;}
        @if $type == sp{width:31vw;top:100vw;right:4vw;}
      }
      &:nth-child(6){
        @if $type == pc{top:425px;right:0;}
        @if $type == sp{width:31vw;top:121vw;right:4vw;}
      }
      &:nth-child(7){
        @if $type == pc{top:425px;right:315px;}
        @if $type == sp{width:100%;height:36vw;top:60vw;right:0;position: relative;
        img{
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          width: 100%;
          height: auto;
          top: 50%;
          left: 50%;
          position: absolute;
        }
        }
      }
      img{
        opacity:0;
      }
      &.on img{
        -webkit-animation: top_main 1.5s ease-in-out .5s forwards;
        animation: top_main 1.5s ease-in-out .5s forwards;
      }
    }
    img{
      @if $type == pc{
      }
      @if $type == sp{
      max-width:100%;
      }
    }
  }
}

// top_artists
// ==========================================================================
#top_artists {
  img {
    width: 100%;
  }
  @if $type == pc{
    padding: 50px 0 0;
  }
  @if $type == sp{
    padding: 50px 0 0;
  }
  .block_artists {
    border: 1px solid $gray10;
    @if $type == pc{
      padding: 50px;
    }
    @if $type == sp{
      padding: 30px;
    }
    .box {
      @if $type == pc{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 40px 0;
      }
      @if $type == sp{
        margin: 30px 0;
      }
    }
    .top_btn {
      @if $type == sp {
        margin-top: 10px;
      }
    }
    .list_artists {
      display: flex;    
      @if $type == pc{
        gap: 10px;
      }
      @if $type == sp{
        margin-top: -10px;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      li {        
        text-align: center;
        a {
          background: $gray10;
          display: block;
          padding: 12px 10px 10px;
          text-decoration: none;
          @if $type == sp{
            padding: 11px 10px 9px;
          }
          &:hover{
            opacity: 0.7;
          }
        }
        @if $type == pc{
          width: 100%;
        }
        @if $type == sp{
          font-size: 14px;
          margin-top: 10px;
          width: 48%;
        }
      }
    }
  }  
}

.top_btn {
  a {
    display: inline-block;
    border: 1px solid $gray09;
    border-radius: 35px;
    text-decoration: none;
    letter-spacing: 1px;
    position: relative;
    padding: 15px 10px 15px 0;
    width: 170px;
    text-align: center;
    @if $type == sp{
      padding: 10px 5px 10px 0;
      width: 30vw;
    }
    &::before,
    &::after {
      content: "";
      background: $gray03;
      height: 1px;
      position: absolute;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
    &::before {
      top: 0;
      bottom: 0;
      margin: auto;
      width: 25px;
      right: 30px;
      @if $type == sp {
        width: 4vw;
        right: 5vw;
      }
    }
    &::after {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      right: 30px;
      top: 27px;
      width: 6px;
      @if $type == sp {
        right: 5vw;
        top: 18px;
        width: 1.2vw;
      }
    }
    &:hover {
      color: $sub_color;
      border: 1px solid $sub_color;
      &::before,
      &::after {
        background: $sub_color;
      }
    }
  }
}

// top_item
// ==========================================================================
#item.top_item {
  @if $type == pc{
    padding: 100px 0 0;
    #blc_wrap {
      .col3 li {
        width: 30%;
      }
      .col4 li {
        width: 23%;
      }
    }
  }
  @if $type == sp{
    padding: 50px 0 0;
  }
}

// top_block
// ==========================================================================
#top_block {
  img {
    width: 100%;
  }
  .flex_box {
    .box_text {
      h2 {
        padding-bottom: 15px;
      }
      .top_btn {
        padding-top: 20px;
      }
    }
  }
  @if $type == pc{
    padding: 100px 0 0;
    .flex_box {
      align-items: center;
      display: flex;
      justify-content: space-between;
      .img {
        width: 30%;
      }
      .box_text {
        margin-left: 30px;
        width: 67%;
      }
    }
  }  
  @if $type == sp{
    padding: 50px 0 0;
    .flex_box {
      .img {
        margin-bottom: 20px;
      }
    }
  }
}

// top_access
// ==========================================================================
.top_access {
  @if $type == pc{
    padding: 100px 0;
  }
  @if $type == sp{
    padding: 50px 0 40px;
  }
  h2 {
    margin-bottom: 40px;
    text-align: center;
    @if $type == sp {
      margin-bottom: 15px;
    }
  }
  #blc_access {
    .access_cont {
      overflow: hidden;
      .map {
        width: 100%;
        border: 1px solid #f0f0f0;
        padding-bottom: 35%;
        position: relative;
        overflow: hidden;
        @if $type == sp{
          padding-bottom: 65%;
          margin-top: 15px;
        }
        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      
      table {
        width: 100%;
        @if $type == pc{
          margin-top: 30px;
          border-top: 1px solid #f0f0f0;
          border-left: 1px solid #f0f0f0;
        }
        @if $type == sp{
          margin-top: 20px;
          border: 1px solid #f0f0f0;
          th, td {
            display: block;
            border-bottom: 1px solid #fff;
          }
          tr:last-child td:last-child { border-bottom: none; }
        }
        th {
          background: #ebf3f5;
          @if $type == pc{
            border-bottom: 1px solid #fff;
            width: 315px;
            padding: 20px 25px;
          }
          @if $type == sp{            
            display: block;
            padding: 10px;
          }
        }
        td {
          letter-spacing: 2px;
          @if $type == pc{
            border-bottom: 1px solid #f0f0f0;
            border-right: 1px solid #f0f0f0;
            padding: 20px 55px;
          }
          @if $type == sp{
            padding: 13px;
          }
        }
      }
    }
  }
}

// top_ws
// ==========================================================================
#top_ws{
  background:$bg_gray;
  @if $type == pc{
    padding:95px 0 100px;
  }
  @if $type == sp{
    padding:65px 0 60px;
  }
  .top_ttl{
    text-align:center;
    @if $type == pc{
    }
    @if $type == sp{
    }
  }
  .ws_wrap{
    @if $type == pc{
      @include inner;
      @include flex(flex-start,space-between,row,nowrap);
      padding:70px 0 0;
    }
    @if $type == sp{
      padding:35px 15px 0;
    }
  }
  .pickup{
    @if $type == pc{
      width:530px;
    }
    @if $type == sp{
    }
    li{
      @if $type == pc{
        width:350px;
        padding-top:40px;
      }
      @if $type == sp{
        padding-top:28px;
      }
      &:nth-child(even){
        @if $type == pc{
          margin-left:180px;
        }
        @if $type == sp{
        }
      }
      &:first-child{
          padding-top: 0;
        @if $type == pc{
        }
        @if $type == sp{
        }
      }
    }
    a{
      display:block;
      text-decoration:none;
      @if $type == pc{
      }
      @if $type == sp{
      }
      &:hover .pic{
        @if $type == pc{
          box-shadow:3px 3px 10px 0 rgba(0,0,0,0.1);
          @include trans($duration: .5);
        }
      }



    }
    .pic{
      text-align:center;
      @if $type == pc{
        width:350px;
        height:230px;
        line-height:230px;
        @include trans($duration: .3);
      }
      @if $type == sp{
        width:53vw;
        height:39vw;
        line-height:39vw;
      }
      img{
        max-width:100%;
        max-height:100%;
        vertical-align:top;
        @if $type == pc{
        }
        @if $type == sp{
        }
      }
    }
    dl{
      background:$white;
      position:relative;
      z-index:1;
      @if $type == pc{
        padding:20px;
        width:310px;
        margin:-65px auto 0;
      }
      @if $type == sp{
        padding:15px;
        margin:-15vw 0 0 31vw;
      }
      dt{
        color:$sub_color;
        font-family:$font_arvo;
        @if $type == pc{
          font-size:12px;
        }
        @if $type == sp{
          font-size:10px;
        }
      }
      dd{
        @if $type == pc{
          font-size:14px;
        }
        @if $type == sp{
          font-size:12px;
        }
      }
    }
  }
  .calendar{
    background:$white;
    @if $type == pc{
      width:550px;
      padding:42px 42px 42px 35px;
    }
    @if $type == sp{
      margin:30px 0 0;
      padding:20px 15px;
    }
    .month{
      font-family:$font_crimson;
      @if $type == pc{
        font-size:17px;
        padding:0 0 5px 25px;
      }
      @if $type == sp{
        font-size:12px;
        padding:0 0 3px 15px;
      }
    }
    .calendar_list{
      @if $type == pc{
        height:695px;
        padding:0 25px;
      }
      @if $type == sp{
        height:395px;
        padding:0 15px;
      }
      ul:first-of-type{
        li:first-child{
          position:relative;
          @if $type == pc{
          }
          @if $type == sp{
          }
          &::before{
            content:"TODAY";
            color:$sub_color;
            position: absolute;
            -moz-transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            transform: rotate(-90deg);
            @if $type == pc{
              font-size: 13px;
              top: 20px;
              left: -42px;
              letter-spacing: 2px;
            }
            @if $type == sp{
              font-size: 10px;
              top: 14px;
              left: -31px;
              letter-spacing: 1px;
            }
          }
          .day,.week{
            background:$sub_color;
            color:$white !important;
          }
          div{
            position:relative;
            &::after{
              content:"";
              position: absolute;
              top: 0;
              bottom:0;
              margin:auto;
              left: 0;
              border:7px solid transparent;
              border-left:11px solid $sub_color;
              height:0;
              width:0;
              @if $type == pc{
              }
              @if $type == sp{
              }
            }
          }
        }
      }
      li{
        border-bottom:1px solid $gray09;
        @include flex(stretch,flex-start,row,nowrap);
        @if $type == pc{
        }
        @if $type == sp{
        }
        &.sun .day,&.sun .week{
          color:$sun;
        }
        &.sat .day,&.sat .week{
          color:$sat;
        }
        div{
          width:76%;
          @if $type == pc{
            padding:12px 0 12px 20px;
            font-size:14px;
          }
          @if $type == sp{
            padding:10px 0 10px 15px;
            font-size:12px;
          }
          > p {
            border-top:1px solid $gray08;
            @if $type == pc{
              padding-top:8px;
              margin-top:8px;
            }
            @if $type == sp{
              padding-top:5px;
              margin-top:5px;
            }

            &:first-child {
              border-top:none;
              padding-top:0;
              margin-top:0;
            }
          }
          .fclose{
            @if $type == pc{
              margin-right:10px;
            }
            @if $type == sp{
            }
          }
        }
      }
      .day,.week{
        width:12%;
        @include flex(center,center,row,nowrap);
        font-family:$font_crimson;
        @if $type == pc{
          padding:18px 0;
        }
        @if $type == sp{
          padding:10px 0;
        }
      }
      .day{
        @if $type == pc{
          font-size:18px;
        }
        @if $type == sp{
          font-size:16px;
        }
      }
      .week{
        background:$bg_gray;
        @if $type == pc{
          font-size:12px;
        }
        @if $type == sp{
          font-size:10px;
        }
      }
    }
    .btn{
      text-align:center;
      @if $type == pc{
        padding:40px 0 0;
      }
      @if $type == sp{
        padding: 20px 0 0;
      }
      a{
        display:inline-block;
        border:1px solid $gray09;
        border-radius:35px;
        text-decoration:none;
        letter-spacing:1px;
        position:relative;
        @if $type == pc{
          padding:15px 10px 15px 0;
          width:170px;
        }
        @if $type == sp{
          padding:10px 5px 10px 0;
          width:30vw;
        }
        &::before,&::after{
          content:"";
          background:$gray03;
          height:1px;
          position:absolute;
          @if $type == pc{
            @include trans($duration: .5);
          }
          @if $type == sp{
          }
        }
        &::before{
          top:0;
          bottom:0;
          margin:auto;
          @if $type == pc{
            width:25px;
            right:30px;
          }
          @if $type == sp{
            width:4vw;
            right:5vw;
          }
        }
        &::after{
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          @if $type == pc{
            right: 30px;
            top: 27px;
            width: 6px;
          }
          @if $type == sp{
            right:5vw;
            top:18px;
            width:1.2vw;
          }
        }
        &:hover{
          @if $type == pc{
            color:$sub_color;
            border:1px solid $sub_color;
            &::before,&::after{
              background:$sub_color;
              @include trans($duration: .3);
            }
            &::before{
              right:22px;
            }
            &::after{
              right: 22px;
            }
          }
        }
      }
    }
    .simplebar-scrollbar:before {
      background: $sub_color;
      opacity:1;
    }
  }
}
