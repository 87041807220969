/* title */

.title_area {
  @if $type == pc {
    height: 490px;
    background-attachment: fixed;
    background-size: cover;
    background-position:center;
    background-repeat:no-repeat;
  }
  @if $type == sp{
    background-size: 100% auto;
    height: 300px;
  }
  h2 {
    font-family: $font_crimson;
    font-weight: 400;
    color: #fff;
    line-height: 1.3em;
    margin: 0 auto;
    @if $type == pc {
      width: 1130px;
      padding-top: 110px;
      font-size: 50px;
      letter-spacing: 5px;
    }
    @if $type == sp {
      width: calc(100% - 30px);
      padding-top: 65px;
      font-size: 28px;
      letter-spacing: 2px;
    }
    span {
      display: block;
      @if $type == pc {
        font-size: 22px;
        margin: 4px 0 0 8px;
        letter-spacing: 2px;
      }
      @if $type == sp {
        font-size: 16px;
      }
    }
  }
}

.title01 {
  color: #595757;
  text-align: center;
  line-height: 1.4em;
  letter-spacing: 3px;
  @if $type == pc{
    font-size: 30px;
    margin-bottom: 40px;
  }
  @if $type == sp{
    font-size: 18px;
    margin-bottom: 30px;
  }
}

.title02 {
  color: #585858;
  text-align: center;
  @if $type == pc{
    font-size: 28px;
  }
  @if $type == sp{
    font-size: 18px;
  }
}

.title03 {
  color: #595757;
  text-align: center;
  line-height: 1.4em;
  letter-spacing: 3px;
  @if $type == pc{
    font-size: 24px;
    margin-bottom: 37px;
  }
  @if $type == sp{
    font-size: 18px;
    margin: 30px 0 20px;
  }
}

