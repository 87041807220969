// ==========================================================================
// footer
// ==========================================================================
#footer{
  text-align:center;
  position:relative;
  @if $type == pc{
    padding:60px 0 50px;
  }
  @if $type == sp{
    padding:40px 0 30px;
  }

  .footer_bnr {
    padding: 0 0 30px;
    margin: 0 auto;
    max-width: 840px;
    a {
      display: block;
      &:hover {
        opacity: 0.7;
      }
    }
    img {
      width: 100%;
    }
  }

  h2{
    a{
      display:inline-block;
      @if $type == pc{
        width: 72px;
      }
      @if $type == sp{
        width: 65px;
      }
    }
    img{
      width:100%;
    }
  }
  .copy{
    display:block;
    @if $type == pc{
      padding:20px 0 0;
      font-size:12px;
    }
    @if $type == sp{
     padding:10px 0 0;
      font-size:10px;
    }
  }
}

#footer_nav{
  @if $type == pc{
    padding:25px 0 0;
  }
  @if $type == sp{
    padding:25px 0 0;
  }
  a{
    display:inline-block;
    text-decoration:none;
    position:relative;
    padding:0 10px;
    @if $type == pc{
      font-size:12px;
    }
    @if $type == sp{
      font-size:10px;
    }
    &::before,&::after{
      content:"";
      background:$gray02;
      width:1px;
      position:absolute;
      top:0;
      bottom:0;
      margin:auto;
      @if $type == pc{
        height:10px;
        font-size:12px;
      }
      @if $type == sp{
        height:8px;
        font-size:10px;
      }
    }
    &::before{
      left:0;
    }
    &::after{
      right:0;
    }
    @include opacity();
  }
}

#pagetop_btn{
  position:fixed;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  opacity:0;
  @include trans();
  z-index: 99;
  @if $type == pc{
    right:40px;
    bottom:60px;
  }
  @if $type == sp{
    right:30px;
    bottom:150px;
  }
  a{
    position:relative;
    text-decoration:none;
    display:block;
    font-size:10px;
    @if $type == pc{
      padding: 30px 0 0 10px;
    }
    @if $type == sp{
      padding: 28px 0 0 6px;
    }
    &:hover{
      @if $type == pc{
        @include opacity();
      }
    }
    &::before,&::after{
      content:"";
      background:$gray03;
      width:1px;
      position:absolute;
      @if $type == pc{
        @include trans($duration: .5);
      }
      @if $type == sp{
      }
    }
    &::before{
      left:0;
      top:0;
      @if $type == pc{
        height:88px;
      }
      @if $type == sp{
        height:86px;
      }
    }
    &::after{
      left:3px;
      top:0;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      @if $type == pc{
        height:7px;
      }
      @if $type == sp{
        height:8px;
      }
    }
    &:hover{
      @if $type == pc{
        &::before,&::after{
          @include trans($duration: .3);
          top:-8px;
        }
      }
    }
  }
}

.fixed #pagetop_btn{
  opacity:1;
}
