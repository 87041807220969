// ==========================================================================
// item
// ==========================================================================

#item {
  color: $main_color;
  .title_area {
    background-image:url(../../img/item/bg_title.jpg);
  }
  .tab_list {
    font-size: 0;
    text-align: center;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    @if $type == pc{ margin-bottom: 64px; }
    @if $type == sp{ margin-bottom: 20px; }
    li {
      display: inline-block;
      letter-spacing: 0.1em;
      cursor: pointer;
      position: relative;
      @include trans($duration: .2);
      @if $type == pc{
        font-size: 14px;
        padding: 15px 23px;
        &:hover {
          color: $aqua;
          &:after { background: #e5e5e5; }
        }
      }
      @if $type == sp{
        font-size: 12px;
        padding: 10px 9px;
        width: calc(100% / 3 - 1px);
        &:nth-child(-n+2) {
          width: calc(50% - 1px);
          &:after { width:calc(100% - 80px); }
        }
      }
      &:after {
        content: '';
        display: block;
        width: calc(100% - 40px);
        height: 4px;
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        @include trans($duration: .2);
      }
      &.active {
        &:after { background: #e5e5e5; }
      }
    }
  }
  #blc_wrap {
    > div:not(:first-child) {
      display: none;
    }
    #blc_ig {
      .ig_list {
        overflow: hidden;
        @if $type == pc{
          margin-right: -22px;
        }
        li {
        overflow: hidden;
        float: left;
        position: relative;
        @if $type == pc{
          width: 190px;
          height: 190px;
          margin: 0 22px 22px 0;
        }
        @if $type == sp{
          width: calc(50% - 5px);
          height: 0;
          padding-top: 50%;
          margin-bottom: 10px;
          &:nth-child(even) { margin-right: 10px; }
        }
        &:first-child {
          @if $type == pc{
            width: 402px;
            height: 402px;
          }
          @if $type == sp{
            width: 100%;
            padding-top: 100%;
            float: none;
          }
        }
        a {
          display:block;
          width:100%;
          height:100%;
          @if $type == sp{
            position: absolute;
            top: 0;
          }
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            font-family: 'object-fit: cover;';
          }
          .ov {
            opacity:0;
            z-index:1;
            @if $type == pc{
              position: absolute;
              top:0;
              left:0;
              height:100%;
              width:100%;
              background: rgba($sub_color,.9);
              color:$white;
              font-family:$font_sans;
              font-size:12px;
              padding:35px 40px;
              @include trans();
              overflow: hidden;
              width: -webkit-calc(100% + 20px);
              width: calc(100% + 20px);
              height: 100%;
              -webkit-overflow-scrolling: touch;
              overflow-y: scroll;
              .date{
                padding:20px 0 0;
              }
            }
          }
          &:hover .ov{
            @if $type == pc{
              opacity:1;
            }
          }
          .date { font-family:$font_arvo; }
        }
      }
      }
      .btn_area {
        margin-top: 38px;
        .btn_next02 a:hover {
          border: 1px solid #c7006c;
          background: #c7006c;
          color: #fff;
          &:before, &:after {
            background: #fff;
          }
        }
      }
    }
  
  #blc_fb {
    ul {
      @include flex($justy:space-between, $wrap:wrap);
      li {
        @if $type == pc{
          width: 320px;
          &:nth-child(n+4) { margin-top: 46px; }
          &:nth-child(3n+2):last-child {
            margin-left: auto;
            margin-right: 360px;
          }
        }
        @if $type == sp{
          width: calc(50% - 8px);
          margin-top: 27px;
          &:nth-child(-n+2) { margin-top: 0; }
        }
        a {
          display: block;
          text-decoration: none;
          &:hover {
            .pic:after { border: 3px solid $aqua; }
            p { color: $aqua; }
          }
          .pic {
            position: relative;
            @if $type == pc{
              width: 320px;
              height: 240px;
            }
            @if $type == sp{
              width: 100%;
              height: 110px;
            }
            &:after {
              content: '';
              display: block;
              width: calc(100% - 5px);
              height: calc(100% - 6px);
              border: 3px solid transparent;
              position: absolute;
              top: 0;
              left: 0;
              @include trans($duration: .2, $ease: ease);
            }
            img {
              object-fit: contain;
              width: 100%;
              height: 100%;
              font-family: 'object-fit: contain;';
            }
          }
          .date {
            font-family: $font_arvo;
            color: #c9c9c9;
            display: block;
            @if $type == pc{
              font-size: 12px;
              margin: 13px 7px 2px;
            }
            @if $type == sp{
              font-size: 10px;
              margin: 7px 7px 2px;
            }
          }
          p {
            line-height: 1.5em;
            letter-spacing: 0.06em;
            margin: 0 10px;
            @include trans($duration: .2, $ease: ease);
          }
        }
      }
    }
    .btn_area {
      margin-top: 55px;
      .btn_next02 a:hover {
        border: 1px solid #4267B2;
        background: #4267B2;
        color: #fff;
        &:before, &:after {
          background: #fff;
        }
      }
    }
  }
  
  .main {
    img {
      width: 100%;
      @if $type == pc{ margin-bottom: 25px; }
      @if $type == sp{ margin-bottom: 15px; }
    }
    > p {
      text-align: center;
      letter-spacing: 0.1em;
    }
  }
  
  .col3, .col4 {
    @include flex($justy:space-between, $wrap:wrap);
    li {
      h5 {
        border-bottom: 2px solid #e94620;
        display: inline-block;
        letter-spacing: 2px;
        line-height: 1.5em;
      }
      img { width: 100%; }
    }    
  }
  
  .col3 {
    margin-top: 40px;
    li {
      @if $type == pc{
        width: 320px;
        &:nth-child(n+4) {
          margin-top: 40px;
        }
      }
      @if $type == sp{
        margin-top: 27px;
        &:first-child { margin-top: 0; }
      }
      h5 {
        @if $type == pc{
          font-size: 20px;
          margin: 23px 0 15px;
          padding-bottom: 5px;
        }
        @if $type == sp{
          font-size: 14px;
          margin: 10px 0 7px;
          padding-bottom: 3px;
        }
      }
      @if $type == pc{
        p { font-size: 14px; }
      }
    }
  }
  
  .col4 {
    @if $type == pc{ margin-top: 47px; }
    @if $type == sp{ margin-top: 30px; }
    li {
      @if $type == pc{ width: 240px; }
      @if $type == sp{
        width: calc(50% - 8px);
        margin-top: 25px;
        &:nth-child(-n+2) {
          margin-top: 0;
        }
      }
      h5 {
        padding-bottom: 2px;
        @if $type == pc{
          font-size: 18px;
          margin: 19px 0 14px;
        }
        @if $type == sp{
          font-size: 13px;
          margin: 10px 0 7px;
        }
      }
      p { font-size: 11px; }
    }
  }
  
  .type_aqua {
    h4 span { color: #4cb0c5; }
    .col3, .col4 {
      li {
        h5 {
          width: 100%;
          text-align: center;
          border-bottom: 2px solid #e5e5e5;
          span {
            font-size: 14px;
            color: #1791aa;
            line-height: 1.3em;
            display: block;
            margin: 3px 0 2px;
          }
        }
        p { text-align: center; }
      }
    }
    .col4 li h5 span {
      font-size: 12px;
      margin-top: 5px;
    }
  }
  
  #blc_hand {
    .main {
      position: relative;
      h4 {
        margin-top: -10%;
      }
      h5 {
        width: 100%;
        text-align: right;
        @if $type == pc{
          position: absolute;
          top: 412px;
          right: 0;
          width: 200px;
        }
        @if $type == sp{ margin-bottom: 20px; }
        span {
          color: #1791aa;
          line-height: 1.3em;
          display: block;
          @if $type == pc{
            font-size: 13px;
            margin-top: 3px;
          }
          @if $type == sp{ font-size: 11px; }
        }
      }
    }
    .col3, .col4 {
      li h5 {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}
}








