// ==========================================================================
// news
// ==========================================================================

#news {
  .title_area {
    @if $type == pc {
      background-image:url(../../img/news/bg_title.jpg);
    }
    @if $type == sp {
      background-image:url(../../img/news/bg_title_sp.jpg);
    }
  }
  
  .page_title {
    text-align: center;
    @if $type == pc{
      font-size: 26px;
      margin-bottom: 35px;
    }
    @if $type == sp{
      font-size: 18px;
      margin-bottom: 27px;
    }
    h3 {
      letter-spacing: 3px;
      line-height: 1.4em;
      span {
        display: block;
        @if $type == pc{ font-size: 22px; }
        @if $type == sp{ font-size: 14px; }
      }
    }
    p {
      font-family: $font_arvo;
      color: #868686;
      margin-top: 2px;
      @if $type == pc{ font-size: 14px; }
      @if $type == sp{ font-size: 11px; }
    }
  }
  
  .pic {
    text-align: center;
    margin-bottom: 75px;
    img { max-width: 100%; }
  }
  
  .txt {
    text-align: left;
    color: #4d4b4b;
    @if $type == pc{
      width: 765px;
    }
    @if $type == sp{
      
    }
  }
}

