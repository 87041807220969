/* content */
.inner {
  @include inner;
}
img {
  height: auto;
  @if $type == pc {
    max-width: 100%;
  }
  @if $type == sp {
    max-width: 100%;
  }
}
.container {
  background: #fff;
  @if $type == pc {
    /* content_pc */
    padding: 150px 0 90px;
  }
  @if $type == sp {
    /* content_sp */
    padding: 60px 0 20px;
  }
}
.content {
  /* content_common */
  background: #fff;  
  @if $type == pc {
    /* content_pc */
    width: 1200px;
    padding: 28px 80px;
    margin: -205px auto 0;
  }
  @if $type == sp {
    /* content_sp */
    padding: 13px 15px;
    margin: -121px 15px 0;
  }
  
  .btn_area {
    text-align: center;
    @if $type == pc{ margin-top: 50px; }
    @if $type == sp{ margin-top: 20px; }
    > p {
      &:nth-child(n+2) {
        @if $type == pc{ margin-top: 27px; }
        @if $type == sp{ margin-top: 16px; }
      }
      a, a:before, a:after { @include trans($ease: ease); }
    }
    .btn_apply {
      a {
        background: $aqua;
        color: #fff;
        text-align: center;
        text-decoration: none;
        letter-spacing: 2px;
        display: inline-block;
        position: relative;
        @if $type == pc{
          font-size: 18px;
          padding: 12px 72px 11px 57px;
          border-radius: 28px;
        }
        @if $type == sp{
          padding: 11px 42px 8px 23px;
          border-radius: 20px;
        }
        &:before, &:after {
          content: '';
          height: 1px;
          background: #fff;
          display: block;
          position: absolute;
          @if $type == pc{ right: 30px; }
          @if $type == sp{ right: 16px; }
        }
        &:before {
          width: 5px;
          transform: rotate(40deg);
          @if $type == pc{ top: 27px; }
          @if $type == sp{ top: 19px; }
        }
        &:after {
          @if $type == pc{
            width: 27px;
            top: 28px;
          }
          @if $type == sp{
            width: 17px;
            top: 20px;
          }
        }
        @if $type == pc{
          &:hover {
            background: #59c9e0;
            &:before, &:after { right: 22px; }
          }
        }
      }
    }
    .btn_end {
      display: block;
      color: #9f1818;
      background: #f3f3f3;
      margin: 0 auto;
      cursor: default;
      @if $type == pc{
        font-size: 18px;
        width: 193px;
        padding: 12px 57px 11px;
        border-radius: 28px;
      }
      @if $type == sp{
        width: 108px;
        border-radius: 20px;
        padding: 11px 0 8px;
      }
    }
    .btn_back {
      a {
        border: 1px solid #b8b8b8;
        text-decoration: none;
        letter-spacing: 2px;
        display: inline-block;
        position: relative;
        @if $type == pc{
          font-size: 18px;
          padding: 12px 57px 11px 72px;
          border-radius: 28px;
        }
        @if $type == sp{
          padding: 11px 23px 8px 43px;
          border-radius: 20px;
        }
        &:before, &:after {
          content: '';
          height: 1px;
          background: #0f0f0f;
          display: block;
          position: absolute;
          @if $type == pc{ left: 30px; }
          @if $type == sp{ left: 16px; }
        }
        &:before {
          width: 5px;
          transform: rotate(-40deg);
          @if $type == pc{ top: 27px; }
          @if $type == sp{ top: 19px; }
        }
        &:after {
          @if $type == pc{
            width: 27px;
            top: 28px;
          }
          @if $type == sp{
            width: 17px;
            top: 20px;
          }
        }
        &:hover {
          background: #f3f3f3;
          &:before, &:after { left: 22px; }
        }
      }
    }
    .btn_next02 {
      a {
        border: 1px solid #b8b8b8;
        text-decoration: none;
        letter-spacing: 2px;
        display: inline-block;
        position: relative;
        @if $type == pc{
          font-size: 18px;
          padding: 12px 72px 11px 57px;
          border-radius: 28px;
        }
        @if $type == sp{
          padding: 11px 43px 8px 23px;
          border-radius: 20px;
        }
        &:before, &:after {
          content: '';
          height: 1px;
          background: #0f0f0f;
          display: block;
          position: absolute;
          @if $type == pc{ right: 34px; }
          @if $type == sp{ right: 16px; }
        }
        &:before {
          width: 5px;
          transform: rotate(40deg);
          @if $type == pc{ top: 27px; }
          @if $type == sp{ top: 19px; }
        }
        &:after {
          @if $type == pc{
            width: 27px;
            top: 28px;
          }
          @if $type == sp{
            width: 17px;
            top: 20px;
          }
        }
        @if $type == pc{
          &:hover {
            &:before, &:after { right: 26px; }
          }
        }
      }
    }
  }
}

.fclose {
  background: $red02;
  color: #fff;
  font-family: $font_sans;
  font-weight: bold;
  @if $type == pc{
    font-size: 12px;
    border-radius: 15px;
    padding: 5px 10px 4px;
  }
  @if $type == sp{
    font-size: 10px;
    display: inline-block;
    padding: 1px 5px;
    border-radius: 10px;
    margin-right: 7px;
    vertical-align: 1px;
  }
}
