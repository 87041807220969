@charset "UTF-8";
$type: common phone tablet screen;
    @import "mixin/mixin";
    @import "base/base";
    @import "base/reset";
    @import "plugin/fontawesome";
    @import "plugin/slick";
    @import "plugin/_slick-theme";
    @import "plugin/jquery.modal";
    @import url('https://fonts.googleapis.com/css?family=Arvo|Crimson+Text&display=swap');
    @import "layout/content";
    @import "layout/form";
    @import "layout/header";
    @import "layout/footer";
    @import "layout/title";
    @import "page/top";
    @import "page/inpage";
    @import "page/workshop";
    @import "page/news";
    @import "page/contact";
    @import "page/item";
    @import "page/artist";

@media print, screen and (min-width:769px) {
    $type: pc;
    @import "mixin/mixin";
    @import "base/base";
    @import "layout/content";
    @import "layout/form";
    @import "layout/header";
    @import "layout/footer";
    @import "layout/title";
    @import "page/top";
    @import "page/inpage";
    @import "page/workshop";
    @import "page/news";
    @import "page/contact";
    @import "page/item";
    @import "page/artist";
}

@media only screen and (max-width:768px) {
    $type: sp;
    @import "mixin/mixin";
    @import "base/base";
    @import "layout/content";
    @import "layout/form";
    @import "layout/header";
    @import "layout/footer";
    @import "layout/title";
    @import "page/top";
    @import "page/inpage";
    @import "page/workshop";
    @import "page/news";
    @import "page/contact";
    @import "page/item";
    @import "page/artist";
}

