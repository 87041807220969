// ==========================================================================
// workshop
// ==========================================================================

#workshop {
  .title_area {
    @if $type == pc {
      background-image:url(../../img/workshop/bg_title.jpg);
    }
    @if $type == sp {
      background-image:url(../../img/workshop/bg_title_sp.jpg);
    }
  }
  
  .tel_box {
    margin-top: 15px;
    text-align: center;
    @if $type == sp{
      font-size: 14px;
    }
    p {
      background: #ebf3f5;
      display: inline-block;
      color: #4d4b4b;
      letter-spacing: 1px;
      @if $type == pc{
        padding: 14px 30px 15px;
      }
      @if $type == sp{
        width: calc(100% + 30px);
        padding: 14px;
        margin: 0 -15px;
        line-height: 1.3em;
      }
      i {
        margin-right: 6px;
      }
      .tel_txt {
        @if $type == sp{
          display: block;
          line-height: 1.5em;
        }
      }
      .tel_num {
        letter-spacing: 0.06em;
        @if $type == pc{
          font-size: 22px;
          margin-left: 10px;
        }
        @if $type == sp{
          font-size: 18px;
          margin-right: 5px;
        }
      }
      .time {
        @if $type == pc{
          font-size: 12px;
        }
        @if $type == sp{
          font-size: 10px;
          vertical-align: 4px;
        }
      }
    }
  }
  
  #blc_calender {
    @if $type == pc{
      margin-top: 120px;
    }
    @if $type == sp{
      margin-top: 55px;
    }
    .title01 {
      @if $type == pc{
        margin-bottom: 0;
      }
      @if $type == sp{
        margin-bottom: 20px;
      }
    }
    .calender_box {
      .date_area {
        @if $type == pc{
          text-align: right;
        }
        @if $type == sp{
          text-align: center;
        }
        .date {
          font-family: $font_arvo;
          display: inline-block;
          @if $type == pc{
            font-size: 20px;
            margin: 0 25px;
          }
          @if $type == sp{
            font-size: 14px;
            margin: 0 18px;
          }
        }
        .prev, .next {
          padding: 12px 5px 14px;
          display: inline-block;
          vertical-align: middle;
          position: relative;
          transition: .3s ease;
          @if $type == pc{  }
          @if $type == sp{ font-size: 14px; }
          &:hover {
            &:before, &:after {
              background: $aqua;
            }
          }
          &:before {
            content: '';
            display: block;
            width: 5px;
            height: 1px;
            background: #3f3f3f;
            position: absolute;
            bottom: 15px;
            transition: .3s ease;
          }
          &:after {
            content: '';
            display: block;
            height: 1px;
            background: #3f3f3f;
            position: relative;
            transition: .3s ease;
            @if $type == pc{
              width: 27px;
            }
            @if $type == sp{
              width: 19px;
            }
          }
        }
        .prev {
          &:hover {
            margin: 0 7px 0 -7px;
          }
          &:before {
            transform: rotate(-35deg);
            left: 5px;
          }
        }
        .next {
          &:hover {
            margin: 0 -7px 0 7px;
          }
          &:before {
            transform: rotate(35deg);
            right: 5px;
          }
        }
      }
    }
    
    .calendar_list {
      @if $type == pc{
        margin-top: 18px;
      }
      @if $type == sp{
        margin-top: 10px;
      }
      ul {
        @if $type == pc{ display: flex; }
        &.wday_list {
          text-align: center;
          @if $type == sp{
            display: none;
          }
          li {
            font-family: $font_arvo;
            font-size: 14px;
            background: #f8f8f8;
            border-right: none;
            min-height: initial;
            min-height: auto;
            padding: 10px;
            &.wday_sun {
              background: #b94343;
              color: #fff;
            }
            &.wday_sat {
              background: #2656af;
              color: #fff;
            }
          }
        }
        li {
          @if $type == pc{
            width: calc(100% / 7);
            min-height: 100px;
            border-right: 1px solid #b8b8b8;
            border-bottom: 1px solid #b8b8b8;
            padding: 14px 16px;
            position: relative;
            &:first-child { border-left: 1px solid #b8b8b8; }
          }
          @if $type == sp{
            width: 100%;
            border-bottom: 1px solid #b8b8b8;
            &.last {
              border-bottom: none;
            }
          }
          &.monthout {
            background: #f8f8f8;
            @if $type == sp{ display: none; }
          }
          dl {
            @if $type == sp{
              display: flex;
              align-items: stretch;
              min-height: 42px;
            }
            dt {
              font-family: $font_crimson;
              line-height: 1em;
              @if $type == pc{
                font-size: 28px;
                margin-bottom: 12px;
              }
              @if $type == sp{
                font-size: 16px;
                width: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              
              &.sat { color: #2656af; }
              &.sun { color: #b94343; }
            }
            .sat + .wday { color: #2656af; }
            .sun + .wday { color: #bd4e4e; }
            dd {
              font-size: 12px;
              width: 100%;
              a:hover { color: $aqua; }
              &.wday {
                @if $type == pc{ display: none; }
                @if $type == sp{
                  background: #f8f8f8;
                  width: 40px;
                  font-size: 10px;
                  display: flex;
                justify-content: center;
                align-items: center;
                }
              }
              @if $type == sp{
                &.event {
                  width: calc(100% - 75px);
                  padding: 10px 14px;
                  a {
                    line-height: 1.6em;
                  }
                }
              }
              > div {
                position: relative;
                border-top:1px solid $gray08;
                @if $type == pc{
                  padding-top:45px;
                  margin-top:8px;
                }
                @if $type == sp{
                  padding-top:5px;
                  margin-top:5px;
                }
                &:first-child {
                  border-top:none;
                  padding-top:0;
                  margin-top:0;
                  .fclose {
                    @if $type == pc{
                      top: -38px;
                    }
                    @if $type == sp{
                    }
                  }
                }
              }
              .fclose {
                @if $type == pc{
                  position: absolute;
                  top: 10px;
                  right: 0;
                }
                @if $type == sp{
                }
              }
            }
          }
        }
      }
    }
  }
  
  // dtl
  #blc_dtl {
    .page_title {
      text-align: center;
      @if $type == pc{ margin-bottom:  55px; }
      @if $type == sp{ margin-bottom: 30px; }
      .title02 { line-height: 1.4em; }
      p {
        font-weight: bold;
        font-family: $font_sans;
        color: #fff;
        background: $red02;
        display: inline-block;
        @if $type == pc{
          font-size: 14px;
          padding: 3px 9px 2px 12px;
          margin-top: 5px;
          border-radius: 20px;
        }
        @if $type == sp{
          font-size: 10px;
          padding: 0 10px;
          line-height: 19px;
          border-radius: 10px;
        }
      }
    }
    
    .slider {
      .slick-slide {
        > div { 
          background: #f7f7f7;
          @if $type == pc{ height: 630px; }
          @if $type == sp{ height: 190px; }
          img { 
            width: 100%;  
            height: 100%; 
            object-fit: contain;
            font-family: 'object-fit: contain;';   /*IE対策*/
          }
        }
      }
    }
    .slider-thumb {
      @if $type == pc{ margin-top: 20px; }
      @if $type == sp{ margin-top: 11px; }
      .slick-slide {
        cursor: pointer;
        > div {
          @if $type == pc{
            width: 108px;
            height: 65px;
            background: #f7f7f7;
          }
          @if $type == sp{
            width: 71px;
            height: 42px;
          }
          img { 
            width: 100%;  
            height: 100%; 
            object-fit: contain;
            font-family: 'object-fit: contain;';   /*IE対策*/
          }
        }
        &.slick-current {
          position: relative;
          &:before {
            content: '';
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            box-sizing: border-box;
            @if $type == pc{
              width: 108px;
              border: 3px solid #88c6d4;
            }
            @if $type == sp{
              width: 71px;
              border: 2px solid #88c6d4;
            }
          }
        }
      }
    }
    .dtl_cont {
      color: #4d4b4b;
      letter-spacing: 0.08em;
      @if $type == pc{
        width: 765px;
        margin-top: 55px;
      }
      @if $type == sp{
        width: 100%;
        margin-top: 35px;
        font-size: 12px;
      }
    }
  }
}

