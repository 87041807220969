.blocker {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  width: 100%; height: 100%;
  overflow: auto;
  z-index: 99999;
  padding: 50px 20px;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.75);
  text-align: center;
}
.blocker:before{
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}
.blocker.behind {
  background-color: transparent;
}
.modal {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  max-width: 800px;
  box-sizing: border-box;
  width: 90%;
}

.modal a.close-modal {
  position: absolute;
  top: -35px;
  right: 0px;
  display: block;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  &::before,
  &::after {
    content: "";
    background: #fff;
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: rotate(45deg) translateY(-50%);
  }
  &::after {
    transform: rotate(-45deg) translateY(-50%);
  }
}

.modal-spinner {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #111;
  height: 20px;
}

.modal-spinner > div {
  border-radius: 100px;
  background-color: #fff;
  height: 20px;
  width: 2px;
  margin: 0 1px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.modal-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.modal-spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.modal-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.5) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.5);
    -webkit-transform: scaleY(0.5);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
