// ==========================================================================
// inpage
// ==========================================================================

.inpage{
  /* inpage_common */
  .pnkz {
    font-size: 0;
    font-weight: 500;
    @if $type == pc { margin: 0 0 75px -40px; }
    @if $type == sp { margin: 0 0 40px; }
    li {
      display: inline-block;
      @if $type == pc{ font-size: 12px; }
      @if $type == sp{ font-size: 10px; }
      &:after {
        content: '＞';
        text-decoration: none;
        display: inline-block;
        margin: 0 6px;
      }
      &:last-child:after { display: none; }
      a {
        color: #898989;
        text-decoration: none;
        border-bottom: 1px solid #c9c9c9;
        @include trans($property: color, $duration: .2, $ease: ease);
        &:hover {
          border-bottom: none;
          color: $aqua;
        }
      }
    }
  }
  
  #blc_main {
    overflow: hidden;
    @if $type == pc{
      margin-bottom: 135px;
    }
    @if $type == sp{
      margin-bottom: 70px;
    }
    img {
      @if $type == pc {
        margin-bottom: 60px;
      }
      @if $type == sp {
        width: 100%;
        margin-bottom: 45px;
      }
    }
    h3 {
      @if $type == pc {
        font-size: 28px;
        letter-spacing: 3px;
        width: 450px;
        float: left;
      }
      @if $type == sp {
        font-size: 19px;
        letter-spacing: 1px;
      }
      em {
        color: $aqua;
      }
    }
    p {
      @if $type == pc {
        width: 550px;
        float: right;
        letter-spacing: 1px;
      }
      @if $type == sp {
        margin-top: 32px;
      }
    }
  }
  
  .txt {
    text-align: center;
    letter-spacing: 2px;
  }
}
