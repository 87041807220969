/* form */

button, input[type=text],input[type="submit"],  textarea, [placeholder] { font-family: -apple-system, "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif; }

input, button, textarea, select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input, textarea {
  font-size: 16px;
  padding: 8px 15px;
  border: none;
  background: #f5f5f5;
}

textarea {
  width: 100%;
  resize: vertical;
  max-height: 300px;
  @if $type == pc{ height: 140px; }
  @if $type == sp{ height: 90px; }
}

.w_l { width: 100%; }
.w_s { width: 90px; }

::-webkit-input-placeholder { color:#ccc; }
:-ms-input-placeholder { color:#ccc; }
::placeholder{ color:#ccc }

.inpage.form {
  @if $type == sp{ .title01 { font-size: 20px; } }
  .form_box {
    width: 100%;
    @if $type == pc{ margin-top: 75px; }
    @if $type == pc{
      &.conf_box th { padding: 28px 60px 28px 30px; }
      tr.cont_txt {
        th { padding: 28px 60px 28px 30px; }
      }
    }
    tr:last-child {
      @if $type == pc{
        th, td { border-bottom: 1px solid #f0f0f0; }
      }
      @if $type == sp{
        td { padding-bottom: 0; }
      }
    }
    th, td {
      line-height: 1.4em;
      @if $type == pc{
        border-top: 1px solid #f0f0f0;
        min-height: 79px;
      }
      @if $type == sp{
        display: block;
      }
    }
    th {
      position: relative;
      text-align: left;
      vertical-align: top;
      @if $type == pc{
        width: 315px;
        padding: 36px 60px 28px 30px;
      }
      @if $type == sp{
        padding: 13px 68px 13px 15px;
        background: #ebebeb;
      }
      &.remarks {
        vertical-align: top;
        margin-bottom: 35px;
        @if $type == sp{
          position: relative;
        }
      }
      .required {
        font-family: $font_sans;
        font-size: 12px;
        color: #fff;
        background: #cf8484;
        padding: 0 10px 0 12px;
        line-height: 20px;
        text-align: center;
        display: inline-block;
        letter-spacing: 2px;
        position: absolute;
        @if $type == pc{
          top: 38px;
          right: 0;
        }
        @if $type == sp{
          top: 11px;
          right: 11px;
          font-weight: normal;
        }
      }
      .txt_s {
        @if $type == pc{ font-size: 14px; }
        @if $type == sp{ font-size: 10px; }
      }
      .note {
        font-family: $font_sans;
        color: #a13535;
        line-height: 1.5em;
        @if $type == pc{
          font-size: 14px;
          margin-top: 15px;
        }
        @if $type == sp{
          font-size: 12px;
          font-weight: normal;
          position: absolute;
          top: 50px;
          left: 0;
        }
        em { font-weight: bold; }
      }
    }
    td {
      @if $type == pc{
        width: calc(100% - 315px);
        padding: 28px 40px 28px 55px;
        margin-left: 315px;
      }
      @if $type == sp{ padding: 20px 0; }
      input.people_num { margin-right: 10px; }
      .error {
        font-family: $font-sans;
        color: $red02;
        margin: 6px 0 0 3px;
        @if $type == pc{ font-size: 14px; }
        @if $type == sp{ font-size: 12px; }
        &:before {
          font-family: $font_awes;
          content: '\f06a';
          font-weight: bold;
          display: inline-block;
          margin-right: 4px;
          vertical-align: -1px;
          @if $type == pc{ font-size: 17px; }
          @if $type == sp{ font-size: 14px; }
        }
      }
    }
  }
  
  &.thanks {
    .thanks_cont {
      text-align: center;
      @if $type == pc{ margin-top: 72px; }
      dt {
        @if $type == pc{ font-size: 24px; }
        @if $type == sp{ font-size: 16px; }
      }
      .txt {
        margin-top: 7px;
        @if $type == pc{ font-size: 18px; }
        @if $type == sp{
          line-height: 1.5em;
          font-size: 12px;
        }
      }
      .point {
        background: #f8f1f1;
        color: $red02;
        display: inline-block;
        letter-spacing: 1px;
        line-height: 1.5em;
        @if $type == pc{
          padding: 37px 40px;
          margin-top: 25px;
        }
        @if $type == sp{
          padding: 15px 20px;
          margin-top: 20px;
          text-align: left;
        }
      }
    }
    .btn_area {
      @if $type == pc{ margin-top: 40px; }
      @if $type == sp{ margin-top: 30px; }
    }
  }
  
  .btn_area {
    text-align: center;
    @if $type == pc{ margin-top: 74px; }
    @if $type == sp{ margin-top: 30px; }
    > p:nth-child(n+2) {
      @if $type == pc{ margin-top: 20px; }
      @if $type == sp{ margin-top: 15px; }
    }
    .btn_next {
      span {
        display: inline-block;
        position: relative;
        input {
          font-family: $font_serif;
          color: #fff;
          background: #2b2b2b;
          letter-spacing: 1px;
          border-radius: 30px;
          display: inline-block;
          cursor: pointer;
          @include trans($ease: ease);
          @if $type == pc{
            line-height: 60px;
            padding: 0 80px 0 70px;
            font-size: 16px;
          }
          @if $type == sp{
            line-height: 55px;
            padding: 0 60px;
          }
          &:hover { background: $aqua; }
        }
        &:before, &:after {
          content: '';
          height: 1px;
          background: #fff;
          display: block;
          position: absolute;
          z-index: 5;
          @include trans($ease: ease);
          @if $type == pc{ right: 30px; }
          @if $type == sp{ right: 20px; }
        }
        &:before {
          width: 5px;
          transform: rotate(35deg);
          @if $type == pc{ top: 28px; }
          @if $type == sp{ top: 26px; }
        }
        &:after {
          @if $type == pc{
            width: 27px;
            top: 29px;
          }
          @if $type == sp{
            width: 20px;
            top: 27px;
          }
        }
        &:hover {
          &:before, &:after { right: 22px; }
        }
      }
    }
    
    .btn_back {
      span {
        display: inline-block;
        position: relative;
        input {
          border: 1px solid #b8b8b8;
          font-family: $font_serif;
          color: $main_color;
          background: #fff;
          letter-spacing: 1px;
          border-radius: 30px;
          display: inline-block;
          cursor: pointer;
          @include trans($ease: ease);
          @if $type == pc{
            line-height: 58px;
            padding: 0 70px 0 80px;
            font-size: 16px;
          }
          @if $type == sp{
            line-height: 53px;
            padding: 0 60px;
          }
          &:hover { background: #f3f3f3; }
        }
        &:before, &:after {
          content: '';
          height: 1px;
          background: #0f0f0f;
          display: block;
          position: absolute;
          @include trans($ease: ease);
          @if $type == pc{ left: 30px; }
          @if $type == sp{ left: 20px; }
        }
        &:before {
          width: 5px;
          transform: rotate(-40deg);
          @if $type == pc{ top: 30px; }
          @if $type == sp{ top: 26px; }
        }
        &:after {
          @if $type == pc{
            width: 27px;
            top: 31px;
          }
          @if $type == sp{
            width: 20px;
            top: 27px;
          }
        }
        &:hover {
          &:before, &:after { left: 22px; }
        }
      }
      @if $type == sp{
        a {
          font-size: 14px;
          line-height: 53px;
          padding: 0 45px 0 55px;
          border-radius: 30px;
          &:before, &:after { left: 20px; }
          &:before { top: 26px; }
          &:after {
            width: 20px;
            top: 27px;
          }
        }
      }
    }
  }
}
