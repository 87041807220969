// ==========================================================================
// contact
// ==========================================================================

#contact {
  .title_area {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: url(../../img/contact/bg_title.jpg) no-repeat center / cover;
    @if $type == pc {
      height: 375px;
    }
    h2 {
      padding: 0;
    }
  }
  #blc_contact {
    .contact_cont {
      overflow: hidden;
      .map {
        width: 100%;
        border: 1px solid #f0f0f0;
        @if $type == pc{ height: 400px; }
        @if $type == sp{
          margin-top: 15px;
          height: 200px;
        }
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      
      table {
        width: 100%;
        @if $type == pc{
          margin-top: 30px;
          border-top: 1px solid #f0f0f0;
          border-left: 1px solid #f0f0f0;
        }
        @if $type == sp{
          margin-top: 20px;
          border: 1px solid #f0f0f0;
          th, td {
            display: block;
            border-bottom: 1px solid #fff;
          }
          tr:last-child td:last-child { border-bottom: none; }
        }
        th {
          background: #ebf3f5;
          @if $type == pc{
            border-bottom: 1px solid #fff;
            width: 315px;
            padding: 20px 25px;
          }
          @if $type == sp{            
            display: block;
            padding: 10px;
          }
        }
        td {
          letter-spacing: 2px;
          @if $type == pc{
            border-bottom: 1px solid #f0f0f0;
            border-right: 1px solid #f0f0f0;
            padding: 20px 55px;
          }
          @if $type == sp{
            padding: 13px;
          }
        }
      }
    }
  }
}
