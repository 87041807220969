// ==========================================================================
// base
// ==========================================================================
*:not(br):not(em) {
  box-sizing: border-box;
  line-height: 1.8em;
}

html, body { height: 100%; }

body { 
  font-family: $font_serif;
  color: $text_color;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @if $type == pc {
    font-size: 16px;
    font-weight: 500;
    overflow-x: hidden;
  }
  @if $type == sp{
    font-size: 12px;
    font-weight: 400;
  }
}

#wrapper {
  flex: 1 1 auto;
  @if $type == pc{
    // min-width: 1200px;
  }
  @if $type == sp{
  }
}

em { font-style: normal; }
.red { color: $red; }

/*  link 
-----------------------------------------------------------------------------*/
a , a:visited {
  color: $text_color;
  text-decoration: underline;
  @include trans($duration: .3);
}

a:hover {
  color: $text_color;
  text-decoration: none;
  @include trans($duration: .5);
}

a img { border: 0; }


/*  device 
-----------------------------------------------------------------------------*/
.pc {
  @if $type == sp{
    display: none !important;
  }
}
.sp {
  @if $type == pc {
    display: none !important;
  }
}

/*  fadein 
-----------------------------------------------------------------------------*/
.fadein {
  opacity : 0;
  transform: translateY(25px);
  @include trans($duration: .8);
}