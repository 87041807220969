// ==========================================================================
// news
// ==========================================================================

#artist {
  .title_area {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    @if $type == pc {
      background: url(../../img/artist/bg_title.jpg) no-repeat center / cover;
      max-height: 375px;
    }
    @if $type == sp {
      background:url(../../img/artist/bg_title_sp.jpg) no-repeat center / cover;
    }
    h2 {
      padding: 0;
    }
  }
  .title03 {
    @if $type == sp {
      margin-top: 0;
    }
  }
  .search_block {
    background: $gray10;
    padding: 50px;
    @if $type == sp {
      padding: 30px;
    }
    .list {
      display: flex; 
      @if $type == pc{
        gap: 10px;
      }
      @if $type == sp{
        margin-top: -10px;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      li {       
        text-align: center;
        a {
          background: $white;
          border: 1px solid $white;
          color: $main_color;
          display: block;
          padding: 8px 10px 4px;
          text-decoration: none;
          &:hover {
            background: $black02;
            border: 1px solid $black02;
            color: $white;
          }
        }      
        @if $type == pc{
          width: 100%;
        }
        @if $type == sp{
          font-size: 14px;
          margin-top: 10px;
          width: 48%;
        }
        &.active {
          a {
            background: $black02;
            border: 1px solid $black02;
            color: $white;
          }
        }
      }
    }
    .search_box {
      border-top: 1px solid $gray01;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 30px;
      margin-top: 30px;
      @if $type == sp {
        padding-top: 20px;
        margin-top: 20px;
      }
      input[type="text"] {
        background: $white;
        color: $text_color;
        font-family: $font_serif;
        height: 50px;
        width: calc(100% - 160px);
        @if $type == sp {
          height: 45px;
          font-size: 14px;
          width: calc(100% - 70px);
        }
        &:focus {
          border: none;
        }
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $gray11;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $gray11;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $gray11;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $gray11;
        }
      }
      .search_btn {
        background: $black02;
        color: $white;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 150px;
        text-align: center;
        @if $type == sp {
          font-size: 14px;
          height: 45px;
          width: 60px;
        }
        &:hover {
          opacity: 0.7;
        }
        input {
          opacity: 0;
          position: absolute;
          height: 0;
          width: 0;
        }
      }
    }
  }
  .item_block {
    margin-top: 80px;
    @if $type == sp {
      margin-top: 40px;
    }
    .item_list {
      display: flex;
      flex-wrap: wrap;
      @if $type == pc {
        margin: -30px -1% 0;
      }
      @if $type == sp {
        justify-content: space-between;
        margin-top: -20px;
      }
      li {
        word-break: break-all;
        @if $type == pc {
          padding: 30px 1% 0;
          width: 33.33%;
        }
        @if $type == sp {
          padding: 20px 0 0;
          width: 48%;
        }
        a.link {
          text-decoration: none;
          &:hover {
            opacity: 0.7;
          }
        }
        .img {
          border: 1px solid $gray01;
          display: block;
          position: relative;
          overflow: hidden;
          height: 254px;
          width: 100%;
          @if $type == sp {
            height: 30vw;
          }
          img {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }
        .ttl {
          display: block;
          font-size: 20px;
          padding: 10px 0;
          @if $type == sp {
            font-size: 18px;
          }
          .small_txt {
            display: block;
            font-size: 14px;
            font-family: $font_crimson;
            @if $type == sp {
              font-size: 12px;
            }
          }
        }
        .link_txt {
          a {
            color: $gray11;
            font-family: $font_crimson;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }
  }
  .zoom_block {
    padding-top: 60px;
    margin: 0 auto;
    max-width: 800px;
    @if $type == sp {
      font-size: 14px;
    }
    @if $type == sp {
      padding: 30px 15px 0;
    }
    .box_ttl {
      margin-bottom: 10px;
      .category_txt {
        border: 1px solid $gray01;
        font-size: 14px;
        display: inline-block;
        padding: 3px 10px 0;
        text-align: center;
        @if $type == sp {
          font-size: 12px;
        }
      }
      h3 {
        font-size: 34px;
        font-weight: 400;
        padding: 5px 0 10px;
        @if $type == sp {
          font-size: 24px;
        }
        span {
          display: block;
          font-size: 20px;
          font-family: $font_crimson;
          @if $type == sp {
            font-size: 16px;
          }
        }
      }
      .link_txt {
        a {
          color: $gray11;
          font-family: $font_crimson;
          text-overflow: ellipsis;
          display: -webkit-box;
          line-clamp: 1;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          @if $type == sp {
            font-size: 14px;
          }
        }
      }
    }
    .description {
      margin-top: 60px;
      .description_txt {
        margin-top: 20px;
      }
      @if $type == sp {
        margin-top: 30px;
      }
      .img {
        text-align: center;
      }
    }
    .img_list {
      display: flex;
      gap: 20px;
      @if $type == sp {
        gap: 7px;
      }
    }
  }
  .modal {
    .close-modal {
      display: none;
    }
    .box-img {
      display: inline-block;
      position: relative;
      .close_popup {
        display: block;
      }
    }
  }
}

